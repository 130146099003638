require("easy-autocomplete")
require("packs/autocomplete")

function init() {
	document.addEventListener("turbolinks:load", function() {
	  var options = {
		  url: function(phrase) {
		    return "/assistants/subjects_uploads.json";
		  },
		  getValue: function(element) {
		    return element.wholename;
		  },
		  list: {
				match: {
					enabled: true
				},
				onClickEvent: function() {
					$("#search_subject_form").submit();
				}
			},
			placeholder: "Nom du patient...",
		  ajaxSettings: {
		    dataType: "json",
		    method:   "GET",
		    data: {
		      dataType: "json"
		    }
		  },
		  preparePostData: function(data) {
		    data.phrase = $("#example-ajax-post").val();
		    return data;
		  },
		  requestDelay: 400
		};




		var dummyOptions = {
		  url: function(phrase) {
		    return "/assistants/subjects_uploads.json";
		  },
		  getValue: function(element) {
		    return element.wholename;
		  },
		  list: {
				match: {
					enabled: true
				},
				onSelectItemEvent: function() {
					var value = $("#search_patient").getSelectedItemData().id
					$("#document_subject_id").val(value).trigger("change");
				}
			},
			placeholder: "Nom du patient...",
		  ajaxSettings: {
		    dataType: "json",
		    method:   "GET",
		    data: {
		      dataType: "json"
		    }
		  },
		  preparePostData: function(data) {
		    data.phrase = $("#example-ajax-post").val();
		    return data;
		  },
		  requestDelay: 400
		};
		

	  $('*[data-behavior="autocomplete"]').easyAutocomplete(options);
	  $('*[data-behavior="autocomplete-dummy"]').easyAutocomplete(dummyOptions);
	});
}

$(document).ready(
	init()
);

$(document).on("turbolinks:load", function() {
	init()
});
