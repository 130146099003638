// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("bootstrap")
require("@fortawesome/fontawesome-free/js/all")
require("jquery")
import $ from 'jquery';
// global.$ = jQuery;
window.jQuery = $;
window.$ = $;

require("easy-autocomplete")
require("packs/autocomplete")
// require("@nathanvda/cocoon")


// autorealod page every 10 sec. if #autoreload found
$(document).on("turbolinks:load", function() {
	if($("#autoreload").length > 0) {
    setTimeout(function(){
    	if($("#autoreload").length > 0) {
      	window.location.reload(true);
      }
    }, 10000);
  }
});

